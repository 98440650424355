import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // Code goes here
  static targets = [ "calendar", "placeholder", "button" ]

  // Check connection
  connect() {
  }

  start() {
    this.calendarTarget.innerHTML = ""
    this.buttonTargets.forEach((el, index) => {
      // el.classList.add('disabled') // to be coded
    })
    var template = document.importNode(this.placeholderTarget.content, true);
    this.calendarTarget.appendChild(template)
  }
}
