import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="calculation-tool"
export default class extends Controller {
  static targets = [ "today", "input", "endDate", "officeClosed" ]

  connect() {}

  search(e) {
    e.preventDefault()
    this.endDateTarget.classList.add('hidden')
    this.officeClosedTarget.classList.add('hidden')

    let customDate = this.inputTarget.value
    let endDate, enteredDate, tempDate, reason1, reason2, we, holiday

    this.inputTarget.value == "" ? enteredDate = new Date() : enteredDate = new Date(customDate)
    endDate = new Date(enteredDate)
    endDate.setMonth(enteredDate.getMonth() + 2)
    endDate.setDate(endDate.getDate() + 10)

    tempDate = new Date(endDate)
    var Holidays = require('date-holidays')
    var hd = new Holidays('LU')
    hd.getHolidays(endDate.getFullYear())

    // Check if holiday
    if ( endDate.getDay() === 6 || endDate.getDay() === 0 || hd.isHoliday(endDate.getTime()) ) {
      while ( endDate.getDay() === 6 || endDate.getDay() === 0 || hd.isHoliday(endDate.getTime()) ) {
        endDate.setDate(endDate.getDate() + 1)
        hd.getHolidays(endDate.getFullYear())
      }
    }

    // hd.getHolidays(endDate.getFullYear()).forEach((item, i) => {
    //   if ( endDate.getTime() > item.start.getTime() && endDate.getTime() < item.end.getTime() ) {
    //     reason2 = "public holiday in Luxembourg"
    //     holiday = true
    //     while ( endDate.getTime() > item.start.getTime() && endDate.getTime() < item.end.getTime() ) {
    //       endDate.setDate(endDate.getDate() + 1)
    //     }
    //   }
    // })


    // Display endDate
    if ( tempDate.getTime() === endDate.getTime() ) {
      this.endDateTarget.innerHTML = 'Deadline set to: ' + endDate.toDateString()
    } else {
      this.officeClosedTarget.classList.remove('hidden')
      this.officeClosedTarget.innerHTML = 'Initial deadline falls on a day when offices are closed in Luxembourg: ' + tempDate.toDateString()
      this.endDateTarget.innerHTML = 'Final deadline set to: ' + endDate.toDateString()
    }

    this.endDateTarget.classList.remove('hidden')
  }
}
