// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

// FONTAWESOME
import "@fortawesome/fontawesome-free/js/all";
FontAwesome.config.mutateApproach = 'sync'

// JQUERY
import jquery from "jquery"
window.$ = jquery

// Alpine.js
import Alpine from 'alpinejs'
window.Alpine = Alpine
Alpine.start()

// Action text
import "trix"
import "@rails/actiontext"

// date-holiday
import "date-holidays"

// TAILWIND ELEMENTS
import 'tw-elements';
