import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="translator"
export default class extends Controller {
  static targets = [ "input", "lang", "output" ]

  // Check connection
  connect() {
    this.outputTarget.classList.add("hidden")
  }

  translate(e) {
    const url = "https://api-free.deepl.com/v2/translate"
    const key = "4155f0fe-9e57-02f8-2327-49fa9c852f3a:fx"
    const text = this.inputTarget.innerText
    const lang = this.langTarget.selectedOptions[0].value
    const _this = this

    $.ajax({
      method: "POST",
      url: url,
      data: {
          auth_key: key,
          text: text,
          target_lang: lang
      },
      success: function(result){
          _this.outputTarget.classList.remove("hidden")
          _this.outputTarget.value = result.translations[0].text;
          
          if (!text) {
            _this.outputTarget.classList.add("hidden")
          }
      },
      error: function(result){
          _this.outputTarget.value = "An error has occured. Please try again later."
      }
    });
  }
}
