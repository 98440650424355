import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // Code goes here
  static targets = ["caseNumber"]

  // Check connection
  connect() {
  }

  // Search function for case number
  search(e) {
    e.preventDefault()
    let caseNumber = this.caseNumberTarget.value
    // Replace '/' by '%252F' to match destination URL
    caseNumber = caseNumber.replace('/', '%252F')
    const url = "https://curia.europa.eu/juris/liste.jsf?num=" + caseNumber + "&language=en"
    window.open(url, '_blank').focus()
  }
}
