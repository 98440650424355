import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="forms"
export default class extends Controller {
  static targets = [ "input", "projectForm" ]

  connect() {
  }

  reset() {
    this.inputTargets.forEach(function(input) {
      input.value = ""
    })
  }

  submit(e) {
    if (e.keyCode == 13 && !e.shiftKey) {
      this.projectFormTarget.requestSubmit();
      return
    }
  }
}
